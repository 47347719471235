<template>
    <div>
        <div class="columns">
            <Kategori />
            <div class="section column borderKonten" v-if="objek.length == 0">
                <div>
                    <div v-if="objek.length == 0">
                        <div v-for="(item, i) in items2" :key="i.idObjek">
                            <p id="judulDetail" class="title">{{item.namaObjek}}</p>
                            <div class="row">
                                <div id="column">
                                    <img :src="item.gambar1" alt="" style="width:100%">
                                </div>
                                <div id="column">
                                    <img :src="item.gambar2" alt="" style="width:100%">
                                </div>
                                <div id="column">
                                    <img :src="item.gambar3" alt="" style="width:100%">
                                </div>
                            </div> 
                            <br>
                            <p class="isiDetail">{{item.deskripsiObjek}}</p>
                            <p id="source">Sumber: <a :href="item.sumber" target="_blank" v-if="item.sumber">{{item.sumber}}, </a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="objek.length != 0" class="section column borderKonten">
                <div class="container">
                    <div id="divKonten" class="title is-4"><span id="judulKonten">{{objek.idObjek}}</span></div>
                    <div class="row">
                        <div id="column" v-for="(i, index) in gambar" :key="index">
                            <div>
                                <img v-if="getIconPath(i) != ''"  :src="getIconPath(i)" alt="" style="width:20rem;height:15rem;object-fit:cover; object-position: 0% 10%">
                            </div>
                        </div>
                    </div>
                    <p class="isiDetail" v-html="objek.deskripsi"></p>
                    <div>
                        <span v-if="objek.sutradara">
                            <div v-if="objek.sutradara.length != 0" class="isiDetail" style="width: 50%"><b>Sutradara : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(sutradara, i) in objek.sutradara" :key="i.sutradara">
                                        <span>{{sutradara}}</span>
                                    </li>
                                </ul>
                            </div>
                        </span>
                        <span v-if="objek.produser">
                            <div v-if="objek.produser.length != 0" class="isiDetail" style="width: 50%"><b>Produser : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(produser, i) in objek.produser" :key="i.produser">
                                        <span>{{produser}}</span>
                                    </li>
                                </ul>
                            </div>
                        </span>
                        <span>
                            <p v-if="objek.caraBermain" v-html="objek.caraBermain" class="isiDetail"></p>
                        </span>
                        <span>
                            <p v-if="objek.lirik" v-html="objek.lirik"></p>
                        </span>
                        <span>
                            <p v-if="objek.langkah" v-html="objek.langkah"></p>
                        </span>
                        <span>
                            <p v-if="objek.kategori" class="isiDetail"><b>Kategori : </b> {{objek.kategori}}</p>
                        </span>
                        <span>
                            <p v-if="objek.asal" class="isiDetail"><b>Asal : </b> {{objek.asal.replace(/['"]+/g, '')}}</p>
                        </span>
                        <span>
                            <p v-if="objek.makananKhas" class="isiDetail"><b>Makanan Khas : </b> {{objek.makananKhas.replace(/['"]+/g, '')}}</p>
                        </span>
                        <span>
                            <p v-if="objek.kabupaten" class="isiDetail"><b>Kabupaten : </b> {{objek.kabupaten.replace(/['"]+/g, '')}}</p>
                        </span>
                        <span>
                            <p v-if="objek.kecamatan" class="isiDetail"><b>Kecamatan : </b> {{objek.kecamatan.replace(/['"]+/g, '')}}</p>
                        </span>
                        <span>
                            <p v-if="objek.memilikiMotif" class="isiDetail"><b>Memiliki Motif : </b> {{objek.memilikiMotif.replace(/['"]+/g, '')}}</p>
                        </span>
                        <span>
                            <p v-if="objek.berbahanDasar" class="isiDetail"><b>Berbahan Dasar : </b> {{objek.berbahanDasar.replace(/['"]+/g, '')}}</p>
                        </span>
                        <span v-if="objek.genre">
                            <div v-if="objek.genre.length != 0" class="isiDetail" style="width: 50%"><b>Genre : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(genre, i) in objek.genre" :key="i.genre">
                                        <span>{{genre}}</span>
                                    </li>
                                </ul>
                            </div>
                        </span>
                        <span>
                            <p v-if="objek.tahun" class="isiDetail"><b>Tahun : {{objek.tahun}}</b></p>
                        </span>
                        <span v-if="objek.pemeran">
                            <div v-if="objek.pemeran.length != 0" class="isiDetail" style="width: 50%"><b>Pemeran : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(pemeran, i) in objek.pemeran" :key="i.pemeran">
                                        <span>{{pemeran}}</span>
                                    </li>
                                </ul>
                            </div>
                        </span>

                        <span>
                            <p v-if="objek.labelPA" class="isiDetail"><b>Penata Artistik : {{objek.labelPA}}</b></p>
                        </span>
                        <span>
                            <p v-if="objek.labelPK" class="isiDetail"><b>Penata Kamera : {{objek.labelPK}}</b></p>
                        </span>
                        <span>
                            <p v-if="objek.labelPM" class="isiDetail"><b>Penata Musik : {{objek.labelPM}}</b></p>
                        </span>
                        <div style="display:flex;">
<!-- Show Data Candi -->
                            <div v-if="arca.length != 0" class="isiDetail" style="width: 50%"><b>Arca : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(arca, i) in arca" :key="i.arca">
                                        <span>{{arca}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="relief.length != 0" class="isiDetail"><b>Relief : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(relief, i) in relief" :key="i.relief">
                                        <span>{{relief}}</span>
                                    </li>
                                </ul>
                            </div>
<!-- Show Data Film -->
                            <div v-if="objek.penataArtistik && objek.penataArtistik.length != 0" class="isiDetail" style="width: 50%"><b>Penata Artistik : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(penataArtistik, i) in objek.penataArtistik" :key="i.penataArtistik">
                                        <span>{{penataArtistik}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="objek.penataGambar && objek.penataGambar.length != 0" class="isiDetail"><b>Penata Gambar : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(penataGambar, i) in objek.penataGambar" :key="i.penataGambar">
                                        <span>{{penataGambar}}</span>
                                    </li>
                                </ul>
                            </div>
<!-- Show Data Alat Musik -->
                            <div v-if="bahan.length != 0" class="isiDetail"><b>Bahan : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(bahan, i) in bahan" :key="i.bahan">
                                        <span>{{bahan}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div style="display:flex;">
<!-- Data Candi -->
                            <div v-if="batu.length != 0" class="isiDetail" style="width: 50%"><b>Batu : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(batu, i) in batu" :key="i.batu">
                                        <span>{{batu}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="tipe.length != 0" class="isiDetail"><b>Tipe : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(tipe, i) in tipe" :key="i.tipe">
                                        <span>{{tipe}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="objek.penataKamera && objek.penataKamera.length != 0" class="isiDetail" style="width: 50%"><b>Penata Kamera : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(penataKamera, i) in objek.penataKamera" :key="i.penataKamera">
                                        <span>{{penataKamera}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="objek.penataMusik && objek.penataMusik.length != 0" class="isiDetail"><b>Penata Musik : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(penataMusik, i) in objek.penataMusik" :key="i.penataMusik">
                                        <span>{{penataMusik}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
<!-- Data Film -->
                        <div v-if="perusahaan.length != 0" class="isiDetail"><b>Perusahaan Produser : </b>
                            <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                <li v-for="(item, i) in perusahaan" :key="i.perusahaanProduser">
                                    <span>{{item.perusahaanProduser}}</span>
                                </li>
                            </ul>
                        </div>
                        <div style="display:flex">
<!-- Data Candi -->
                            <div v-if="bagianDari.length != 0" class="isiDetail" style="width: 50%"><b>Bagian Dari : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(bagianDari, i) in bagianDari" :key="i.bagianDari">
                                        <span>{{bagianDari}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="terdiriDari.length != 0" class="isiDetail"><b>Terdiri Dari : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(terdiriDari, i) in terdiriDari" :key="i.terdiriDari">
                                        <span>{{terdiriDari}}</span>
                                    </li>
                                </ul>
                            </div>
<!-- Data Alat Musik -->
                            <div v-if="kegunaan.length != 0" class="isiDetail"><b>Kegunaan : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(kegunaan, i) in kegunaan" :key="i.kegunaan">
                                        <span>{{kegunaan}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div v-if="objek.penulisSkenario && objek.penulisSkenario.length != 0" class="isiDetail" style="width: 50%"><b>Penulis Skenario : </b>
                                <ul style="margin-left: 1.5rem; padding-top: .5rem; list-style-type: disc;">
                                    <li v-for="(penulisSkenario, i) in objek.penulisSkenario" :key="i.penulisSkenario">
                                        <span>{{penulisSkenario}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
<!-- Show Data Keris -->
                        <div>
                            <p v-if="objek.bilah" class="isiDetail"><b>Bilah : </b> {{objek.bilah.replace(/['"]+/g, '')}}</p>
                            <p v-if="objek.dhapur" class="isiDetail"><b>Dhapur : </b> {{objek.dhapur.replace(/['"]+/g, '')}}</p>
                            <p v-if="objek.pamor" class="isiDetail"><b>Pamor : </b> {{objek.pamor.replace(/['"]+/g, '')}}</p>
                            <p v-if="objek.tangguh" class="isiDetail"><b>Tangguh : </b> {{objek.tangguh.replace(/['"]+/g, '')}}</p>
                            <p v-if="objek.ukiranKayu" class="isiDetail"><b>Ukiran Kayu : </b> {{objek.ukiranKayu.replace(/['"]+/g, '')}}</p>
                            <p v-if="objek.bentukBilah" class="isiDetail"><b>Bentuk Bilah : </b> {{objek.bentukBilah.replace(/['"]+/g, '')}}</p>
                            <p v-if="objek.bentukUkiran" class="isiDetail"><b>Bentuk Ukiran : </b> {{objek.bentukUkiran.replace(/['"]+/g, '')}}</p>
                            <p v-if="objek.bentukMendhak" class="isiDetail"><b>Bentuk Mendhak : </b> {{objek.bentukMendhak.replace(/['"]+/g, '')}}</p>
                            <p v-if="objek.bentukPendhok" class="isiDetail"><b>Bentuk Pendhok : </b> {{objek.bentukPendhok.replace(/['"]+/g, '')}}</p>
                            <p v-if="objek.bentukWarangka" class="isiDetail"><b>Bentuk Warangka : </b> {{objek.bentukWarangka.replace(/['"]+/g, '')}}</p>
                        </div>
<!-- Data Video Alat Musik -->
                        <div v-if="objek.video">
                            <iframe width="420" height="315"
                                :src="video">
                            </iframe> 
                        </div>
<!-- Data Lokasi Candi -->
                        <span v-if="objek.lokasi">
                            <iframe :src="lokasi" frameborder="0" width="600" height="450" style="border:0" loading="lazy" allowfullscreen referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </span>
<!-- Data Lagu -->
                        <div>
                            <p v-if="objek.birama" class="isiDetail"><b>Briama : </b>{{ objek.birama }}</p>
                            <p v-if="objek.nada" class="isiDetail"><b> Nada : </b>{{ objek.nada }}</p>
                        </div>
<!-- Data Permainan -->
                        <div>
                            <p v-if="objek.namaAlat" class="isiDetail"><b>Nama alat : </b>{{ objek.namaAlat }}</p>
                            <p v-if="objek.sifat" class="isiDetail"><b>Sifat : </b>{{ objek.sifat }}</p>
                            <p v-if="objek.dikenalDi" class="isiDetail"><b>Dikenal di : </b>{{ objek.dikenalDi }}</p>
                            <p v-if="objek.dimainkanDi" class="isiDetail"><b>Dimainkan di : </b>{{ objek.dimainkanDi }}</p>
                            <p v-if="objek.dimainkanKetika" class="isiDetail"><b>Dimainkan ketika : </b>{{ objek.dimainkanKetika }}</p>
                            <p v-if="objek.dimainkanOleh" class="isiDetail"><b>Dimainkan oleh : </b>{{ objek.dimainkanOleh }}</p>
                        </div>
<!-- Data Cerita Rakyat -->
                        <div>
                            <p v-if="objek.Tema" class="isiDetail"><b>Pembuat : </b>{{ objek.Tema }}</p>
                            <p v-if="objek.Pembuat" class="isiDetail"><b>Pembuat : </b>{{ objek.Pembuat }}</p>
                        </div>
<!-- Data Kerajinan -->
                        <div>
                            <p v-if="objek.fungsi" class="isiDetail"><b>Fungsi : </b>{{ objek.fungsi }}</p>
                        </div>
                        <div v-if="objek.langkahMemasak" class="isiDetail">
                            <b>Langkah Memasak: </b>
                            <p v-html="objek.langkahMemasak"></p>
                        </div>
                    </div>
                    <p id="source">Sumber: 
                        <a :href="sumberDB" target="_blank" v-if="sumberDB != '-'">{{sumberDB}}, </a>
                        <a :href="'http://'+dbpedia" target="_blank" v-if="dbpedia != '-'">{{dbpedia}}</a>
                        <a href="" v-else-if="sumberDB == '-' && dbpedia == '-'">-</a>
                    </p>
                </div>  
            </div>     
        </div>           
    </div>
</template>

<script>
import Kategori from '@/components/Kategori.vue'
import axios from "axios"

export default {
    components: {Kategori},
    name: 'DetailObjek',
    props: ['id', 'objek', 'idLabel'],
    data(){
        return{
            infos:[],
            items2: [],
            sumberDB: '-',
            dbpedia: '-',
            gambar: [],
            lokasi: null,

            //data candi
            arca: [],
            relief: [],
            batu: [],
            tipe: [],
            bagianDari: [],
            terdiriDari: [],

            //data alat musik
            video: '-',
            bahan: [],
            kegunaan: [],

            //data film
            genre: [],
            produserFilm: [],
            perusahaan: [],

        }
    },
    created() {
        // this.getDetails();
        this.getObjekById();
        console.log(this.objek);
    },
    methods: {
        async getObjekById() {
            this.gambar = []
            try {
                if(this.objek.length == 0){
                    const response2 = await axios.get(`https://portalapi.alunalun.info/kategori/subkategori/objek/detail/${this.id}`);
                    this.items2 = response2.data;
                    console.log(this.items2);
                }
                // Data candi
                if(this.objek.lokasi){
                    this.lokasi=this.objek.lokasi.replaceAll("\"", "")
                }
                if(this.objek.sumberDB){
                    this.sumberDB=this.objek.sumberDB.replaceAll("\"", "")
                }
                if(this.objek.dbpedia){
                    this.dbpedia=this.objek.dbpedia.replaceAll("\"", "")
                }
                if(this.objek.gambar2){
                    this.gambar = this.objek.gambar2.split(", ")
                    for(let i=0; i<this.gambar.length; i++){
                        this.gambar[i] = this.gambar[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.arca) {
                    this.arca = this.objek.arca.split(", ")
                    for(let i=0; i<this.arca.length; i++){
                        this.arca[i] = this.arca[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.relief) {
                    this.relief = this.objek.relief.split(", ")
                    for(let i=0; i<this.relief.length; i++){
                        this.relief[i] = this.relief[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.batu) {
                    this.batu = this.objek.batu.split(", ")
                    for(let i=0; i<this.batu.length; i++){
                        this.batu[i] = this.batu[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.tipe) {
                    this.tipe = this.objek.tipe.split(", ")
                    for(let i=0; i<this.tipe.length; i++){
                        this.tipe[i] = this.tipe[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.bagianDari) {
                    this.bagianDari = this.objek.bagianDari.split(", ")
                    for(let i=0; i<this.bagianDari.length; i++){
                        this.bagianDari[i] = this.bagianDari[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.terdiriDari) {
                    this.terdiriDari = this.objek.terdiriDari.split(", ")
                    for(let i=0; i<this.terdiriDari.length; i++){
                        this.terdiriDari[i] = this.terdiriDari[i].replaceAll("\"", "")
                    }
                }
                // Alat Musik Tradisional
                if(this.objek.bahan){
                    this.bahan = this.objek.bahan.split(", ")
                    for(let i=0; i<this.bahan.length; i++){
                        this.bahan[i] = this.bahan[i].replaceAll("\"", "")
                        // this.gambar.push(tempGambar)
                    }
                }
                if(this.objek.kegunaan){
                    this.kegunaan = this.objek.kegunaan.split(", ")
                    for(let i=0; i<this.kegunaan.length; i++){
                        this.kegunaan[i] = this.kegunaan[i].replaceAll("\"", "")
                        // this.gambar.push(tempGambar)
                    }
                }
                // Data Batik
                if(this.objek.gambar1){
                    this.gambar = this.objek.gambar1.split(", ")
                    for(let i=0; i<this.gambar.length; i++){
                        this.gambar[i] = this.gambar[i].replaceAll("\"", "")
                        // this.gambar.push(tempGambar)
                    }
                }
                // Data alat musik
                if(this.objek.video){
                    this.video = this.objek.video.replaceAll("\"", "")
                }
                // Data Film
                if(this.objek.pemeran){
                    this.objek.pemeran = this.objek.pemeran.split(", ")
                    for(let i=0; i<this.objek.pemeran.length; i++){
                        this.objek.pemeran[i] = this.objek.pemeran[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.genre){
                    this.objek.genre = this.objek.genre.split(", ")
                    for(let i=0; i<this.objek.genre.length; i++){
                        this.objek.genre[i] = this.objek.genre[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.penataArtistik){
                    this.objek.penataArtistik = this.objek.penataArtistik.split(", ")
                    for(let i=0; i<this.objek.penataArtistik.length; i++){
                        this.objek.penataArtistik[i] = this.objek.penataArtistik[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.penataGambar){
                    this.objek.penataGambar = this.objek.penataGambar.split(", ")
                    for(let i=0; i<this.objek.penataGambar.length; i++){
                        this.objek.penataGambar[i] = this.objek.penataGambar[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.penataKamera){
                    this.objek.penataKamera = this.objek.penataKamera.split(", ")
                    for(let i=0; i<this.objek.penataKamera.length; i++){
                        this.objek.penataKamera[i] = this.objek.penataKamera[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.penataMusik){
                    this.objek.penataMusik = this.objek.penataMusik.split(", ")
                    for(let i=0; i<this.objek.penataMusik.length; i++){
                        this.objek.penataMusik[i] = this.objek.penataMusik[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.penulisSkenario){
                    this.objek.penulisSkenario = this.objek.penulisSkenario.split(", ")
                    for(let i=0; i<this.objek.penulisSkenario.length; i++){
                        this.objek.penulisSkenario[i] = this.objek.penulisSkenario[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.produser){
                    this.objek.produser = this.objek.produser.split(", ")
                    for(let i=0; i<this.objek.produser.length; i++){
                        this.objek.produser[i] = this.objek.produser[i].replaceAll("\"", "")
                    }
                }
                if(this.objek.sutradara){
                    this.objek.sutradara = this.objek.sutradara.split(", ")
                    for(let i=0; i<this.objek.genre.length; i++){
                        this.objek.sutradara[i] = this.objek.sutradara[i].replaceAll("\"", "")
                    }
                }
            } catch (err) {
                console.log(err)
            }
        },
        // async getDetails() {
        //     try {
        //         const response = await axios.get(`https://portalapi.alunalun.info/kategorisubobj/${this.$route.params.id}`);
        //         this.infos = response.data;
        //     } catch (err) {
        //         console.log(err);
        //     }
        // },
        getIconPath(iconName){
            try{
                return iconName ? require(`../assets/${iconName}`) : ''
            }
            catch (err){
                return ''
            }
            
        },
    }
}
</script>

<style scoped>
.card-carousel {
    user-select: none;
    position: relative;
}
.thumbnails {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}
.thumbnail-image {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 2px;
}
.thumbnail-image > img {
    width: 14rem;
    height: 10rem;
    transition: all 250ms;
}
.thumbnail-image:hover > img, 
.thumbnail-image.active > img {
    opacity: 0.6;
    box-shadow: 2px 2px 6px 1px rgba(0,0,0, 0.5);
}
.card-img {
    position: relative;
    margin-bottom: 15px;
}
.card-img > img {
    display: block;
    margin: 0 auto;
}
.actions {
    font-size: 1.5em;
    height: 40px;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #585858;
}
.actions > span {
    cursor: pointer;
    transition: all 250ms;
    font-size: 45px;
}
.actions > span.prev {
    margin-left: 5px;
}
.actions > span.next {
    margin-right: 5px;
}
.actions > span:hover {
    color: #eee;
}
.row {
  display: flex;
}
#column {
  float: left;
  width: 33.33%;
  padding: 5px;
  flex: 33.33%;
  padding: 5px;
}
.row::after {
  content: "";
  clear: both;
  display: table;
}
#source{
    margin-top: 5%;
    float: right;
}
iframe{
    margin: auto;
    margin-top: 10%;
    margin-left: 0%;
    display: flex;
}
</style>